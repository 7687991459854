import { useRest } from '@karpeleslab/react-klbfw-hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Loader from '../components/Loader';
import PageBanner from '../components/PageBanner/PageBanner';
import LayoutHelmet from '../components/LayoutHelmet';

const LegalPage = () => {
  const [datas] = useRest('Content/Cms/@legal:search');
  const { t } = useTranslation();

  console.log(datas);

  return (
    <>
      <LayoutHelmet title={t('legal')} />
      <Header noPlaceholder />
      <PageBanner title={t('legal')} />
      <Container>
        {!datas ? (
          <Loader />
        ) : datas.result === 'success' ? (
          datas.data.data.length ? (
            datas.data.data.map((e) => (
              <section dangerouslySetInnerHTML={{ __html: e.Contents }} />
            ))
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default LegalPage;
