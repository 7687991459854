import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Collapsible from '../components/Collapsible/Collapsible';
import Container from '../components/Container';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import PageBanner from '../components/PageBanner/PageBanner';
import useAnchorListener from '../hooks/useAnchorListener';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import Loader from '../components/Loader';
import LayoutHelmet from '../components/LayoutHelmet';
import InputField from '../components/InputField';
import Btn from '../components/Btn/Btn';
import { rest } from '@karpeleslab/klbfw';
import Alert from '../components/Alert';

const FAQPage = () => {
  useAnchorListener();
  const [openedIndex, setOpenedIndex] = useState();
  const { t } = useTranslation();
  const params = {
    results_per_page: 50,
  }
  const [result] = useRest('Content/Cms/@faq:search', params);
  const [faq, setFaq] = useState(null);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (result) {
      setFaq(result.data.data);
    }
  }, [result, setFaq]);

  const onClick = (index) =>
    setOpenedIndex((value) => (value !== index ? index : undefined));

  const onClickSearch = () => {
    setSearchLoading(true);
    setFaq(null);
    const searchParams = {...params, query: {
        all: search,
      }
    };
    rest('Content/Cms/@faq:search', 'GET', searchParams)
      .then((result) => {
        setFaq(result.data.data);
      })
      .finally(() => {
        setSearchLoading(false);
      });
  }

  const getContent = () => {
    if (!faq || searchLoading) {
      return <Loader />
    }

    if (faq.length === 0) {
      return <Alert type={'info'} >
        {t('no_result_found')}
      </Alert>
    }


    return faq.map((cms, i) => (
      <Collapsible
        key={i}
        className="mb-4"
        title={cms.Title}
        startIcon={
          <span className="font-bold font-montserrat text-[24px]">Q.</span>
        }
        open={openedIndex === i}
        onClick={() => onClick(i)}
      >
        <div dangerouslySetInnerHTML={{ __html: cms.Contents }} />
      </Collapsible>
    ));
  }

  return (
    <>
      <LayoutHelmet title={t('faq')} />
      <Header noPlaceholder />
      <PageBanner title={t('faq')} />
      <Container className="mb-32">
        <div className={'flex justify-center items-center mb-10'}>
          <InputField
            placeholder={t('search')}
            fullWidth
            wrapperClassName={'inline-flex mb-0 mr-4'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Btn onClick={onClickSearch} disabled={searchLoading} >{t('send')}</Btn>
        </div>
        {getContent()}
      </Container>
      <Footer />
    </>
  );
};

export default FAQPage;
