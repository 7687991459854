import { rest } from '@karpeleslab/klbfw';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '../components/Alert';
import Btn from '../components/Btn/Btn';
import Container from '../components/Container';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import InputField from '../components/InputField';
import Loader from '../components/Loader';
import PageBanner from '../components/PageBanner/PageBanner';
import LayoutHelmet from '../components/LayoutHelmet';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState();
  const [sent, setSent] = useState(false);
  const [error, setError] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError();
    rest('User:forgot_password', 'POST', { login: email })
      .then(() => setSent(true))
      .catch((err) => setError(`${err.error}`))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <LayoutHelmet title={t('password_forgotten')} />
      <Header noPlaceholder />
      <PageBanner
        title={t('password_forgotten')}
        classes={{ container: 'text-[40px] lg:text-[60px]' }}
      />
      <Container component="main">
        {!sent ? (
          <div key="request-form" className="w-full">
            <p className="mt-4 mb-8">{t('password_forgotten_description')}</p>
            <form
              action=""
              className="flex flex-col items-center"
              onSubmit={onSubmit}
            >
              <InputField
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                label={t('email_address')}
                fullWidth
                inputClassName="min-w-[300px]"
                placeholder={t('email_placeholder')}
                autoFocus={true}
              />
              {error && (
                <Alert type="error" className="mb-8">
                  {error}
                </Alert>
              )}
              {loading ? (
                <Loader />
              ) : (
                <Btn
                  size="large"
                  variant="primary"
                  type="submit"
                  className="mt-4"
                >
                  {t('send')}
                </Btn>
              )}
            </form>
          </div>
        ) : (
          <Alert>
            <p>{t('password_request_sent')}</p>
          </Alert>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ForgotPasswordPage;
