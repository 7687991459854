import React, { useLayoutEffect } from 'react';
import FeatureSection from '../components/FeatureSection/FeatureSection';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import PageBanner from '../components/PageBanner/PageBanner';
import '../assets/css/Features.css';
import useAnchorListener from '../hooks/useAnchorListener';
import Container from '../components/Container';
import useFeatureList from '../hooks/useFeatureList';
import LayoutHelmet from '../components/LayoutHelmet';
import { useTranslation } from 'react-i18next';

const FeaturesPage = () => {
  const {t} = useTranslation();
  useAnchorListener();
  useLayoutEffect(() => {
    document.querySelector('#root').style.backgroundColor = '#19191B';

    return () => {
      document.querySelector('#root').style.backgroundColor = null;
    };
  }, []);
  const featureList = useFeatureList();

  return (
    <>
      <LayoutHelmet title={t('features')} description={t('feature_artist_description')} />
      <Header noPlaceholder />
      <PageBanner title={t('features')} />
      <Container className="max-w-[1280px]" component="main">
        {featureList.map((f, i) => (
          <FeatureSection key={i} {...f} />
        ))}
      </Container>
      <Footer />
    </>
  );
};

export default FeaturesPage;
