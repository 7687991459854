import React from 'react';
import './PageBanner.css';
import wave from '../../assets/img/soundwave.png';
import Container from '../Container';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const PageBanner = ({ children, title, background, classes = {} }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.4,
        },
      }}
      className={clsx('page-banner', classes.container)}
      style={{ backgroundImage: `url(${background || wave})` }}
    >
      <Container
        component="h1"
        className={clsx(
          'w-full flex justify-end items-center lg:p-0',
          classes.textContainer
        )}
      >
        {children || title}
      </Container>
    </motion.div>
  );
};

PageBanner.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  background: PropTypes.string,
  classes: PropTypes.shape({
    container: PropTypes.string,
    textContainer: PropTypes.string,
  }),
};

export default PageBanner;
