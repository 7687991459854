import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const Container = ({ children, component, className, ...otherProps }) => {
  const Component = component || 'div';

  return (
    <Component
      className={clsx(
        'w-full mx-auto py-12 px-6 md:p-6 max-w-screen-lg',
        className
      )}
      {...otherProps}
    >
      {children}
    </Component>
  );
};

Container.propTypes = {
  component: PropTypes.elementType,
  className: PropTypes.string,
};

export default Container;
