import Container from '../components/Container';
import Header from '../components/Header/Header';
import PageBanner from '../components/PageBanner/PageBanner';
import InputField from '../components/InputField';
import Checkbox from '../components/Checkbox';
import Btn from '../components/Btn/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer/Footer';
import WaveDivider from '../components/WaveDivider';
import useAnchorListener from '../hooks/useAnchorListener';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { rest } from '@karpeleslab/klbfw';
import Loader from '../components/Loader';
import Alert from '../components/Alert';
import LayoutHelmet from '../components/LayoutHelmet';

const ContactPage = () => {
  useAnchorListener();
  const { t } = useTranslation();
  const [Email, setEmail] = useState('');
  const [Name, setName] = useState('');
  const [Subject, setSubject] = useState('');
  const [Message, setMessage] = useState('');
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = (e) => {
    // TODO: submit the form
    e.preventDefault();
    setLoading(true);
    rest('Support/Ticket', 'POST', {
      Email,
      Name,
      To: '@support',
      Subject,
      Message,
    })
      .then(() => setSent(true))
      .catch((err) => setError(`${err.error}`))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <LayoutHelmet title={t('contact')} />
      <Header noPlaceholder />
      <PageBanner title={t('contact')} />
      <Container>
        {sent ? (
          <Alert type="info">{t('message_sent')}</Alert>
        ) : (
          <>
            <p className="text-center">
              <Trans i18nKey="contact_title" />
              <br />
              <span style={{ color: '#E50044' }}>＊</span>
              {t('mandatory_field')}
            </p>
            <WaveDivider />
            <form onSubmit={onSubmit}>
              <InputField
                id="name"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                required
                label={t('name')}
                fullWidth
                placeholder={t('name_placeholder')}
              />

              <InputField
                id="email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                required
                label={t('email')}
                fullWidth
                placeholder={t('email_placeholder')}
              />

              <InputField
                id="subject"
                value={Subject}
                onChange={(e) => setSubject(e.target.value)}
                required
                label={t('subject')}
                fullWidth
                placeholder={t('subject_placeholder')}
              />

              <InputField
                id="message"
                value={Message}
                onChange={(e) => setMessage(e.target.value)}
                required
                label={t('message')}
                fullWidth
                placeholder={t('message_placeholder')}
                multiline
                rows={8}
              />

              <label className="flex items-center justify-center mb-16">
                <Checkbox
                  checked={acceptPolicy}
                  onChange={(e) => setAcceptPolicy(e.target.checked)}
                />
                <span className="ml-4">{t('accept_policy')}</span>{' '}
                <span className="text-pxl-redColor">＊</span>
              </label>

              {error && (
                <Alert type="error" className="mb-8">
                  {error}
                </Alert>
              )}

              {loading ? (
                <Loader />
              ) : (
                <div className="flex justify-center">
                  <Btn
                    className="mb-32"
                    variant="inverted"
                    size="large"
                    type="submit"
                    disabled={!acceptPolicy}
                  >
                    <FontAwesomeIcon icon={faArrowRight} className={'mr-4'} />
                    {t('check')}
                  </Btn>
                </div>
              )}
            </form>
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ContactPage;
