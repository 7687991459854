import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Grid = ({ children, className }) => (
  <div className={clsx('grid gap-x-8 gap-y-16', className)}>{children}</div>
);

Grid.propTypes = {
  className: PropTypes.string,
};

export default Grid;
