import { useRest } from '@karpeleslab/react-klbfw-hooks';
import FadeIn from '../components/animations/FadeIn';
import Footer from '../components/Footer/Footer';
import Loader from '../components/Loader';
import LoadingError from '../components/LoadingError';
import LayoutHelmet from '../components/LayoutHelmet';
import React from 'react';

const { useTranslation } = require('react-i18next');
const { default: Container } = require('../components/Container');
const { default: Header } = require('../components/Header/Header');
const { default: PageBanner } = require('../components/PageBanner/PageBanner');

const PrivacyPage = () => {
  const { t } = useTranslation();
  const [datas] = useRest('Content/Cms/@legal:loadSlug', { slug: 'privacy' });
  const loading = !datas;
  const item = datas?.data?.content_cms_entry_data;

  return (
    <>
      <LayoutHelmet title={t('privacy')} />
      <Header noPlaceholder />
      <PageBanner title={t('privacy')} />
      <Container>
        {loading ? (
          <Loader />
        ) : datas.result === 'success' ? (
          <FadeIn dangerouslySetInnerHTML={{ __html: item.Contents }} />
        ) : (
          <LoadingError />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPage;
