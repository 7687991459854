import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import PageBanner from '../components/PageBanner/PageBanner';
import '../assets/css/News.css';
import Container from '../components/Container';
import Grid from '../components/Grid';
import defaultThumbnail from '../assets/img/default-thumbnail.jpg';
import { NewsCard } from '../components/Card/Card';
import Pagination from '../components/Pagination/Pagination';
import Footer from '../components/Footer/Footer';
import { useLocation } from 'react-router';
import useAnchorListener from '../hooks/useAnchorListener';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import LoadingError from '../components/LoadingError';
import FadeIn from '../components/animations/FadeIn';
import LayoutHelmet from '../components/LayoutHelmet';
import InputField from '../components/InputField';
import Btn from '../components/Btn/Btn';
import { rest } from '@karpeleslab/klbfw';

export const EmptyNews = ({ className }) => {
  const { t } = useTranslation();

  return (
    <FadeIn className={clsx('flex flex-col items-center', className)}>
      <FontAwesomeIcon icon={faFolderOpen} size="6x" className="mb-4" />
      <p className="text-3xl text-center">{t('no_news_found')}</p>
    </FadeIn>
  );
};

const imageVariation = 'strip&scale_crop=305x171';

const NewsPage = () => {
  useAnchorListener();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = params.get('page') || 1;
  const { t } = useTranslation();

  const [data] = useRest('Content/Cms/@news:search', {
    image_variation: imageVariation,
    sort: 'published:desc',
    page_no: page,
    results_per_page: 12,
  });

  const [news, setNews] = useState(null);
  const [hasRestError, setHasRestError] = useState(false);
  const [searchNews, setSearchNews] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    if (data) {
      if (data.result !== 'success') {
        setHasRestError(true);
        return;
      }
      setNews(data.data.data);
    }
  }, [data, setNews]);

  const getContent = () => {
    if (!news) {
      return <Loader />;
    }

    if (hasRestError) {
      return <LoadingError />;
    }

    if (news.length === 0) {
      return <EmptyNews />;
    }

    return <FadeIn>
      <Grid className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-32">
        {data.data.data.map((item, i) => (
          <NewsCard
            key={i}
            to={`/news/${item.Slug}`}
            tags={item.Tags ?? []}
            date={new Date(item.Published.unix * 1000)}
            imgSrc={
              item.Drive_Item
                ? item.Drive_Item.Media_Image[imageVariation] ||
                item.Drive_Item.View_Url
                : defaultThumbnail
            }
            imgAlt={item.Title}
            title={item.Title}
          />
        ))}
      </Grid>
      <Pagination
        pageMax={data.paging.page_max}
        page={parseInt(page) || 1}
        onPageRequest={(i) => console.log('Go to page ' + i)}
        generateLink={(i) => `/news?page=${i}`}
      />
    </FadeIn>;

  }

  const onClickSearch = () => {
    setSearchLoading(true);
    setNews(null);
    const searchParams = {...params, query: {
        all: searchNews,
      }
    };
    rest('Content/Cms/@faq:search', 'GET', searchParams)
      .then((result) => {
        if (result.result !== 'success') {
          setHasRestError(true);
          return;
        }
        setHasRestError(false);
        setNews(result.data.data);
      })
      .finally(() => {
        setSearchLoading(false);
      });
  }

  return (
    <>
      <LayoutHelmet title={t('news')} />
      <Header noPlaceholder />
      <PageBanner title={t('news')} />
      <Container component="main">
        <div className={'flex justify-center items-center mb-10'}>
          <InputField
            placeholder={t('search')}
            fullWidth
            wrapperClassName={'inline-flex mb-0 mr-4'}
            value={searchNews}
            onChange={(e) => setSearchNews(e.target.value)}
          />
          <Btn onClick={onClickSearch} disabled={searchLoading} >{t('send')}</Btn>
        </div>
        {getContent()}
      </Container>
      <Footer />
    </>
  );
};

export default NewsPage;
