import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { useTranslation } from 'react-i18next';
import FadeIn from '../components/animations/FadeIn';
import Container from '../components/Container';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Loader from '../components/Loader';
import LoadingError from '../components/LoadingError';
import PageBanner from '../components/PageBanner/PageBanner';
import LayoutHelmet from '../components/LayoutHelmet';
import React from 'react';

const TermsOfServicePage = () => {
  const { t } = useTranslation();
  const [datas] = useRest('Content/Cms/@legal:loadSlug', { slug: 'tos' });
  const loading = !datas;
  const item = datas?.data?.content_cms_entry_data;

  return (
    <>
      <LayoutHelmet title={t('tos')} />
      <Header noPlaceholder />
      <PageBanner title={t('tos')} />
      <Container>
        {loading ? (
          <Loader />
        ) : datas.result === 'success' ? (
          <FadeIn dangerouslySetInnerHTML={{ __html: item.Contents }} />
        ) : (
          <LoadingError />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfServicePage;
