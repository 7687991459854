import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Btn from '../Btn/Btn';
import logo from '../../logo.svg';
import MenuButton from '../MenuButton/MenuButton';
import './Header.css';
import { Link } from 'react-router-dom';
import { useTabletAndBelowMediaQuery } from '../../hooks/useMediaQueries';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import LangSwitch from '../LangSwitch/LangSwitch';
import useScrollTrigger from '../../hooks/useScrollTrigger';

const Header = ({ position = 'fixed', noPlaceholder = false }) => {
  const { pathname } = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const tabletAndBelow = useTabletAndBelowMediaQuery();
  const { t } = useTranslation();
  const scroll = useScrollTrigger({
    threshold: 160,
  });

  const toggleMenu = () => {
    setShowMenu((v) => {
      v
        ? document.body.classList.remove('overflow-hidden')
        : document.body.classList.add('overflow-hidden');
      return !v;
    });
  };

  /**
   * @param {string} path The path with hash parameter
   */
  const goToSection = (path) => () => {
    // If we are on the same page as the requested element, then scroll it into the view
    if (path.replace(/#.*$/, '') === pathname) {
      const match = /(#.*?)$/.exec(path);
      if (match[1])
        document.querySelector(match[1]).scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
        });
    }

    setShowMenu(() => {
      document.body.classList.remove('overflow-hidden');

      return false;
    });
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <>
      <header className={clsx('navbar', { scroll }, position)}>
        <nav className={'xl:flex hidden items-center z-10'}>
          <Btn to="/features" variant={'discrete'}>
            {t('features')}
          </Btn>
          <Btn
            onClick={goToSection('/#cases')}
            to="/#cases"
            variant={'discrete'}
          >
            {t('cases')}
          </Btn>
          <Btn to="/news" variant={'discrete'}>
            {t('news')}
          </Btn>
          <Btn to="/faq" variant={'discrete'}>
            {t('faq')}
          </Btn>
        </nav>
        <Link
          to="/"
          className="xl:absolute xl:left-1/2 xl:translate-x-[-50%] z-10"
        >
          <img className={'logo'} src={logo} alt={'PXL'} />
        </Link>
        <nav className={'flex items-center z-10'}>
          <Btn
            href="https://admin.pxl.jp/"
            variant={!tabletAndBelow ? 'discrete' : 'with-border'}
          >
            {t('sign_in')}
          </Btn>
          {!tabletAndBelow && (
            <Btn href="https://admin.pxl.jp/" variant={'with-border'}>
              {t('sign_up')}
            </Btn>
          )}
          <MenuButton open={showMenu} onClick={toggleMenu} />
        </nav>
        <AnimatePresence>
          {showMenu && (
            <motion.div
              initial={{ opacity: 0, translateY: -100, scale: 0 }}
              animate={{ opacity: 1, translateY: 0, scale: 1 }}
              exit={{ opacity: 0, translateY: -100, scale: 0 }}
              className={'menu-wrapper left-0 top-0 z-0'}
            >
              <div className={'menu'}>
                <div className={'flex flex-col links'}>
                  <Link to={'/features'}>{t('features')}</Link>
                  <Link to={'/#cases'} onClick={goToSection('/#cases')}>
                    {t('cases')}
                  </Link>
                  <Link to={'/news'}>{t('news')}</Link>
                  <Link to={'/faq'}>{t('faq')}</Link>
                </div>
                <div className="flex flex-col links-2">
                  <Link to="/tos">{t('tos')}</Link>
                  <Link to="/privacy">{t('privacy')}</Link>
                  <Link to="/forgot-password">{t('password_forgotten')}</Link>
                  <div className="flex items-center mb-8">
                    <h6 className="mt-2 mr-2">{t('language')}:</h6>
                    <LangSwitch />
                  </div>
                  <Btn
                    href="https://admin.pxl.jp/"
                    variant={'primary'}
                    size={'large'}
                    className={clsx('mb-8', { 'w-100': tabletAndBelow })}
                  >
                    {t('start_now')}
                  </Btn>
                  <Btn
                    to="/contact"
                    variant={'with-border'}
                    size={'large'}
                    className={clsx('mb-8', { 'w-100': tabletAndBelow })}
                  >
                    {t('contact')}
                  </Btn>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </header>
      {position === 'fixed' && !noPlaceholder && (
        <div className={clsx('navbar-placeholder')} />
      )}
    </>
  );
};

Header.propTypes = {
  position: PropTypes.oneOf(['static', 'fixed']),
};

export default Header;
