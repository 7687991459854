import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import './Pagination.css';

const PaginationButton = ({
  children,
  disabled,
  active = false,
  onClick,
  to,
  className,
}) => {
  const Component = to ? Link : 'button';

  return (
    <Component
      to={to}
      className={clsx(`pagination-button ${className}`, { active })}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Component>
  );
};

const Pagination = ({ pageMax = 1, page = 1, onPageRequest, generateLink }) => {
  if (pageMax === 1) return null;

  return (
    <div className="pagination-container">
      {[...new Array(Math.min(pageMax, 5))].map((_, i) => {
        let index;
        if (pageMax <= 5) index = i + 1;
        else {
          switch (i) {
            case 0:
              index = 1;
              break;
            case 1:
              if (page <= 2) index = 2;
              else index = -2;
              break;
            case 2:
              if (page < pageMax - 1 && page > 2) index = page;
              else index = -3;
              break;
            case 3:
              if (page >= pageMax - 1) index = page;
              else index = -1;
              break;
            case 4:
              index = pageMax;
              break;

            default:
              break;
          }
        }
        const active = page === index;

        const onClick = () =>
          !active && index !== -3
            ? onPageRequest(
                index > 0 ? index : index === -1 ? page + 1 : page - 1
              )
            : null;

        return (
          <PaginationButton
            to={
              generateLink && !active && index !== -3
                ? generateLink(
                    index > 0 ? index : index === -1 ? page + 1 : page - 1
                  )
                : undefined
            }
            onClick={onClick}
            key={i}
            active={active}
            className={index === -3 ? 'no-bg' : ''}
          >
            {index === -3 ? (
              <>...</>
            ) : index === -2 ? (
              <FontAwesomeIcon icon={faArrowLeft} />
            ) : index === -1 ? (
              <FontAwesomeIcon icon={faArrowRight} />
            ) : (
              index
            )}
          </PaginationButton>
        );
      })}
    </div>
  );
};

export default Pagination;
