import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const FadeIn = ({ children, ...otherProps }) => (
  <motion.div
    {...otherProps}
    initial={{ y: -100, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
  >
    {children}
  </motion.div>
);

FadeIn.propTypes = {
  children: PropTypes.node,
};

export default FadeIn;
