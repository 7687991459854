import React, { createRef } from 'react';
import Header from '../components/Header/Header';
import '../assets/css/Home.css';
import homeHeroImage from '../assets/img/home_hero.png';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import Container from '../components/Container';
import Grid from '../components/Grid';
import Btn from '../components/Btn/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FeatureCard, NewsCard } from '../components/Card/Card';
import Footer from '../components/Footer/Footer';
import HorizontalAutoScrollView from '../components/HorizontalAutoScrollView/HorizontalAutoScrollView';
import spotifyLogo from '../assets/img/spotify_logo.png';
import youtubeLogo from '../assets/img/youtube_logo.png';
import deezerLogo from '../assets/img/deezer_logo.png';
import lineMusicLogo from '../assets/img/line_music_logo.png';
import appleMusicLogo from '../assets/img/apple_music_logo.png';
import useAnchorListener from '../hooks/useAnchorListener';
import { Trans, useTranslation } from 'react-i18next';
import 'react-stacked-carousel/dist/index.css';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import Loader from '../components/Loader';
import { EmptyNews } from './News';
import defaultThumbnail from '../assets/img/default-thumbnail.jpg';
import { Mobile, TabletAndAbove } from '../hooks/useMediaQueries';
import useFeatureList from '../hooks/useFeatureList';
import LoadingError from '../components/LoadingError';
import FadeIn from '../components/animations/FadeIn';
import LayoutHelmet from '../components/LayoutHelmet';
import { Cases } from './Cases';

export const ViewAllBtn = ({ to, size = 'large' }) => {
  const { t } = useTranslation();

  return (
    <Btn to={to} className={'mx-auto'} variant={'inverted'} size={size}>
      <FontAwesomeIcon icon={faArrowRight} className={'mr-4'} />
      {t('view_all')}
    </Btn>
  );
};

const imageVariation = 'strip&scale_crop=305x171';

const HomePage = () => {
  useAnchorListener();

  const horizontalScrollRef = createRef();
  const [news] = useRest('Content/Cms/@news:search', {
    image_variation: imageVariation,
    sort: 'published:desc',
    page_no: 1,
    results_per_page: 4,
  });
  const featureList = useFeatureList();

  const renderNews = () =>
    news.data.data.map((item, i) => (
      <React.Fragment key={i}>
        <NewsCard
          key={i}
          to={`/news/${item.Slug}`}
          tags={item.Tags ?? []}
          date={new Date(item.Published.unix * 1000)}
          imgSrc={
            item.Drive_Item
              ? item.Drive_Item.Media_Image[imageVariation] ||
                item.Drive_Item.View_Url
              : defaultThumbnail
          }
          imgAlt={item.Title}
          title={item.Title}
        />
      </React.Fragment>
    ));

  return (
    <>
      <LayoutHelmet />
      <Header noPlaceholder />
      <div
        className="hero-banner"
        style={{ backgroundImage: `url(${homeHeroImage})` }}
      >
        <div className="z-10">
          <p className="mb-2 lg:mb-0 banner-title-wrapper">
            <Trans i18nKey="page_banner_title" />
          </p>
          <p>
            <Trans i18nKey="page_banner_description" />
          </p>
        </div>
      </div>
      <main>
        <HorizontalAutoScrollView
          className="lg:mb-[200px] lg:mt-12 md:mb-32 my-28"
          childrenContainerRef={horizontalScrollRef}
        >
          <ul
            className="inline-flex items-center flex-nowrap h-[80px]"
            ref={horizontalScrollRef}
          >
            <li className="lg:mx-12 mx-6">
              <img
                className="partner-logo"
                src={spotifyLogo}
                alt="Spotify logo"
              />
            </li>
            <li className="lg:mx-12 mx-6">
              <img
                className="partner-logo"
                src={youtubeLogo}
                alt="Youtube logo"
              />
            </li>
            <li className="lg:mx-12 mx-6">
              <img
                className="partner-logo"
                src={deezerLogo}
                alt="Deezer logo"
              />
            </li>
            <li className="lg:mx-12 mx-6">
              <img
                className="partner-logo"
                src={lineMusicLogo}
                alt="Line music logo"
              />
            </li>
            <li className="lg:mx-12 mx-6">
              <img
                className="partner-logo"
                src={appleMusicLogo}
                alt="Apple music logo"
              />
            </li>
          </ul>
        </HorizontalAutoScrollView>
        <Container className="md:px-6 px-0">
          <section className="flex flex-col align-center mb-16 px-6">
            <SectionTitle title="Features" />
            <Grid className="lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mb-16">
              {featureList.map(({ imgSrc, title, sub, subtitle, text1 }, i) => (
                <FeatureCard
                  key={i}
                  to={`/features#${title}`}
                  imgSrc={imgSrc}
                  imgAlt={title}
                  sub={sub}
                  subtitle={subtitle}
                />
              ))}
            </Grid>
            <ViewAllBtn to="/features" />
          </section>

          <section id="cases" className="flex flex-col align-center mb-16 px-6">
            <Cases />
          </section>

          <section className="flex flex-col align-center mb-16">
            <SectionTitle className="px-6" title="News" />
            {news ? (
              news.result === 'success' ? (
                news.data.data.length ? (
                  <FadeIn className={'flex flex-col align-center'}>
                    <Mobile>
                      <div className="mobile-news-wrapper">{renderNews()}</div>
                    </Mobile>
                    <TabletAndAbove>
                      <Grid className="news-wrapper">{renderNews()}</Grid>
                    </TabletAndAbove>
                    <ViewAllBtn to="/news" />
                  </FadeIn>
                ) : (
                  <EmptyNews />
                )
              ) : (
                <LoadingError />
              )
            ) : (
              <Loader className="mb-4" />
            )}
          </section>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default HomePage;
