import React, { createRef, useLayoutEffect, useState } from 'react';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { useTranslation } from 'react-i18next';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import { StackedCarousel } from 'react-stacked-carousel';
import Loader from '../components/Loader';
import defaultThumbnail from '../assets/img/default-thumbnail.jpg';
import Grid from '../components/Grid';

export const Cases = () => {
  const stackCardRef = createRef();
  const { t } = useTranslation();
  const params = {
    results_per_page: 50,
  };
  const [result] = useRest('Content/Cms/@artists:search', params);
  const [content, setContent] = useState(<></>);

  useLayoutEffect(() => {
    // Remove useless carousel buttons :p
    if (stackCardRef && stackCardRef.current) {
      stackCardRef.current.querySelectorAll('span').forEach((e) => e.remove());
    }
  }, [stackCardRef]);

  const onChange = ({ currentIndex }) => {
    const artist = result.data.data[currentIndex];
    const title = artist.Title;
    const description = artist.Contents;
    setContent(<>
      <h6 className='uppercase mb-4'>{title}</h6>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </>);
  };

  return <>
    <SectionTitle title={t('cases')} reverse />
    {!result ? <Loader /> :
      <Grid className='md:grid-cols-2 grid-cols-1 items-center gap-x-12'>
        <div ref={stackCardRef}>
          <StackedCarousel
            autoRotate
            rotationInterval={10000}
            containerClassName='stack-card-container'
            cardClassName='card'
            leftButton={null}
            rightButton={null}
            onCardChange={onChange}
          >
            {result.data.data.map((artist, i) =>
              <div key={i}>
                <img
                  src={artist.Top_Drive_Item ? artist.Top_Drive_Item.View_Url : defaultThumbnail}
                  alt={artist.Title}
                />
              </div>)}
          </StackedCarousel>
        </div>
        <div className='flex flex-col justify-end'>
          {content}
        </div>
      </Grid>
    }
  </>;
};