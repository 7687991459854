import React from 'react';
import PropTypes from 'prop-types';
import './MenuButton.css';
import clsx from 'clsx';

const MenuButton = ({ onClick, open }) => {
  return (
    <button className={clsx('menu-button mr-4', { open })} onClick={onClick}>
      <div />
      <div />
      <div />
    </button>
  );
};

MenuButton.propTypes = {
  onClick: PropTypes.func,
  open: PropTypes.bool,
};

export default MenuButton;
