import React from 'react';
import { trimPrefix } from '@karpeleslab/klbfw';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import './LangSwitch.css';
import Flags from 'country-flag-icons/react/3x2';

const getCountrySVG = (flag) => {
  const props = {
    height: 16,
  };

  return flag === 'US' ? (
    <Flags.US {...props} />
  ) : flag === 'JP' ? (
    <Flags.JP {...props} />
  ) : flag === 'FR' ? (
    <Flags.FR {...props} />
  ) : flag === 'KR' ? (
    <Flags.KR {...props} />
  ) : null;
};

export default function LangSwitch() {
  const [langData] = useRest('Language:local');

  if (!langData) {
    return <></>;
  }

  const reloadWithSelectedLocale = (fullCode) => {
    if (!window) return;

    const [prefix, url] = trimPrefix(window.location.pathname);
    let newPrefix = '';
    prefix['l'] = fullCode;
    Object.keys(prefix).forEach((k) => (newPrefix += `/${k}/${prefix[k]}`));

    window.location.href = newPrefix + url;
  };

  const elements = langData.data.map((lang, i) => {
    const flag = lang.Language__.split('-')[1];
    const emoji = getCountrySVG(flag);

    return (
      <button
        title={lang.Name_Long}
        className={'inline-flex items-center c-lang-switch-flag'}
        onClick={() => reloadWithSelectedLocale(lang.Language__)}
        key={i}
      >
        {emoji}
      </button>
    );
  });

  return <>{elements}</>;
}
