import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { getHostname, getPrefix } from '@karpeleslab/klbfw';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LayoutHelmet = ({ title, description }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  if (!description) {
    description = t('meta_description');
  }

  return (
    <Helmet>
      <meta charset='utf-8' />
      <meta
        name='viewport'
        content='width=device-width,initial-scale=1,user-scalable=no'
      />
      <meta name='theme-color' content='#1e1f20' />

      <title>{title || 'PXL'}</title>
      <meta property='og:title' content={(title ? `${title} - PXL` : 'PXL')} />
      <meta name='twitter:title' content={(title ? `${title} - PXL` : 'PXL')} />
      <link rel='canonical' href={`https://${getHostname()}${getPrefix()}${pathname}`} />
      <meta property='og:url' content={`https://${getHostname()}${getPrefix()}${pathname}`} />
      <meta name='twitter:url' content={`https://${getHostname()}${getPrefix()}${pathname}`} />

      <meta name='description' content={description || ''} />
      <meta property='og:description' content={description || ''} />
      <meta Bio='twitter:description' content={description || ''} />
    </Helmet>
  );
};

LayoutHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default LayoutHelmet;
