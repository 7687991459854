import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import LayoutHelmet from '../components/LayoutHelmet';
import React from 'react';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <LayoutHelmet title={'404'} />
      <Header noPlaceholder />
      <Container
        className="flex-1 h-full flex flex-col justify-center items-center"
        component="main"
      >
        <p className="font-montserrat text-8xl mb-0">404</p>
        <p className="mb-0 text-2xl">{t('not_found')}</p>
      </Container>
      <Footer pinnedToBottom={false} />
    </>
  );
};

export default NotFoundPage;
