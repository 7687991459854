import featureArtist from '../assets/img/feature1.png';
import featureDiscography from '../assets/img/feature2.png';
import featureEvent from '../assets/img/feature3.png';
import featureFanclub from '../assets/img/feature4.png';
import featureShop from '../assets/img/feature5.png';
import featureLanguage from '../assets/img/feature6.png';
import { Trans, useTranslation } from 'react-i18next';

const useFeatureList = () => {
  const { t } = useTranslation();

  return [
    {
      variant: 'overlap',
      reverse: true,
      title: t('feature_artist_title'),
      sub: <Trans i18nKey="feature_artist_sub" />,
      imgSrc: featureArtist,
      cardContent: <Trans i18nKey="feature_artist_category" />,
      subtitle: <Trans i18nKey="feature_artist_subtitle" />,
      description: <Trans i18nKey="feature_artist_description" />,
    },
    {
      variant: 'normal',
      title: t('feature_discography_title'),
      sub: <Trans i18nKey="feature_discography_sub" />,
      imgSrc: featureDiscography,
      cardContent: <Trans i18nKey="feature_discography_category" />,
      subtitle: <Trans i18nKey="feature_discography_subtitle" />,
      description: <Trans i18nKey="feature_discography_description" />,
      classes: {
        subtitle: 'mix-blend-mode-normal',
        description: 'mix-blend-mode-normal',
      },
    },
    {
      variant: 'side',
      reverse: true,
      title: t('feature_event_title'),
      sub: <Trans i18nKey="feature_event_sub" />,
      imgSrc: featureEvent,
      cardContent: <Trans i18nKey="feature_event_category" />,
      subtitle: <Trans i18nKey="feature_event_subtitle" />,
      description: <Trans i18nKey="feature_event_description" />,
      style: {},
    },
    {
      variant: 'overlap',
      title: t('feature_fanclub_title'),
      sub: <Trans i18nKey="feature_fanclub_sub" />,
      imgSrc: featureFanclub,
      cardContent: <Trans i18nKey="feature_fanclub_category" />,
      subtitle: <Trans i18nKey="feature_fanclub_subtitle" />,
      description: <Trans i18nKey="feature_fanclub_description" />,
    },
    {
      variant: 'normal',
      reverse: true,
      title: t('feature_shop_title'),
      sub: <Trans i18nKey="feature_shop_sub" />,
      imgSrc: featureShop,
      cardContent: <Trans i18nKey="feature_shop_category" />,
      subtitle: <Trans i18nKey="feature_shop_subtitle" />,
      description: <Trans i18nKey="feature_shop_description" />,
      classes: {
        subtitle: 'text-black mix-blend-mode-normal',
        description: 'text-black mix-blend-mode-normal',
      },
    },
    {
      variant: 'overlap',
      title: t('feature_language_title'),
      sub: <Trans i18nKey="feature_language_sub" />,
      imgSrc: featureLanguage,
      cardContent: <Trans i18nKey="feature_language_category" />,
      subtitle: <Trans i18nKey="feature_language_subtitle" />,
      description: <Trans i18nKey="feature_language_description" />,
      style: {
        paddingTop: 0,
        paddingRight: '60%',
        backgroundSize: '640px auto',
        minHeight: 0,
      },
    },
  ];
};

export default useFeatureList;
