import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import FadeIn from './animations/FadeIn';

const LoadingError = ({ className }) => {
  const { t } = useTranslation();

  return (
    <FadeIn className={clsx('flex flex-col items-center', className)}>
      <FontAwesomeIcon icon={faTimesCircle} size="6x" className="mb-4" />
      <p className="text-3xl text-center">{t('loading_error')}</p>
    </FadeIn>
  );
};

export default LoadingError;
