import React, { useEffect, useState } from 'react';
import Container from '../components/Container';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import PageBanner from '../components/PageBanner/PageBanner';
import Moment from 'react-moment';
import WaveDivider from '../components/WaveDivider';
import Btn from '../components/Btn/Btn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Redirect, useHistory } from 'react-router';
import useAnchorListener from '../hooks/useAnchorListener';
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import Loader from '../components/Loader';
import { motion } from 'framer-motion';
import { getLocale } from '@karpeleslab/klbfw';
import defaultThumbnail from '../assets/img/default-thumbnail.jpg';
import LayoutHelmet from '../components/LayoutHelmet';
import { useTranslation } from 'react-i18next';

const NewsDetailPage = ({ slug }) => {
  const {t} = useTranslation();
  useAnchorListener();
  const [datas] = useRest('Content/Cms/@news:loadSlug', { slug }, true);

  const [details, setDetails] = useState();
  const loading = !datas;
  const history = useHistory();

  useEffect(() => {
    if (datas && datas.result === 'success') {
      const item = datas.data.content_cms_entry_data;
      setDetails({
        tags: item.Content_Cms_Entry.Tags ?? [],
        date: new Date(item.Published.unix * 1000),
        imgSrc: item.Drive_Item ? item.Drive_Item.View_Url : defaultThumbnail,
        title: item.Title,
        description: item.Contents,
      });
    }
  }, [datas]);

  if (getLocale() !== datas?.data?.content_cms_entry_data?.Language__) {
    const validAlternative = (datas?.alternatives ?? []).filter(
      (a) => a.Language__ === getLocale()
    );

    if (validAlternative.length > 0) {
      return <Redirect to={`/news/${validAlternative[0].key}`} />;
    }
  }

  return (
    <>
      <LayoutHelmet title={!details ? t('news') : details.title} />
      <Header noPlaceholder />
      {loading ? (
        <Loader />
      ) : (
        <>
          <PageBanner title="News" />
          <Container
            component={motion.main}
            initial={{ opacity: 0, y: -100 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 0.4, duration: 0.4 },
            }}
          >
            {details ? (
              <>
                <div className="flex items-center mb-4">
                  {details.tags.map(t => <div className="pxl-gradient-card mr-2">
                    {t.Name}
                  </div>)}
                  <Moment className={'flex items-center'} format={'YYYY.MM.DD'}>
                    {details.date}
                  </Moment>
                </div>
                <h6 className="font-bold">{details.title}</h6>
                <WaveDivider className="mt-8" />
                <div className={'w-full flex justify-center m-0'}>
                  <img
                    className="max-w-[75%] max-h-[75%] md:max-w-[50%] md:max-h-[50%] mb-8"
                    src={details.imgSrc}
                    alt={details.title}
                  />
                </div>
                <p
                  className="mb-32"
                  dangerouslySetInnerHTML={{ __html: details.description }}
                />
              </>
            ) : (
              <div
                className="flex-1 h-full flex flex-col justify-center items-center"
                component="main"
              >
                <p className="font-montserrat text-8xl mb-0">404</p>
                <p className="mb-0 text-2xl">Page not found</p>
              </div>
            )}
            <div className="flex justify-center">
              <Btn size="large" variant="inverted" className="mb-16" to="/news">
                <FontAwesomeIcon
                  onClick={() => history.goBack()}
                  icon={faArrowLeft}
                  className={'mr-4'}
                />
                Index
              </Btn>
            </div>
          </Container>
        </>
      )}
      <Footer />
    </>
  );
};

export default NewsDetailPage;
