import clsx from 'clsx';
import React, { useLayoutEffect, useState } from 'react';
import './HorizontalAutoScrollView.css';

const copies = 4;

const HorizontalAutoScrollView = ({
  children,
  className,
  childrenContainerRef,
}) => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    /**
     * @type {HTMLDivElement|undefined}
     */
    const container = childrenContainerRef && childrenContainerRef.current;

    const setupWidth = () => {
      if (container) {
        if (container.offsetWidth) {
          // Cover the double of the screen width by adjusting the number of copies to show
          setWidth(container.offsetWidth * copies);
        } else {
          // Empty children container
          setWidth(0);
        }
      }
    };

    if (container) {
      // Listen for image load events, when all images are loaded, re setup container's width to prevent from getting inconsistent width at startup
      Promise.all(
        [...container.querySelectorAll('img').values()].map(
          (img) => new Promise((res) => (img.onload = res))
        )
      ).then(() => {
        setupWidth();
      });
    }

    setupWidth();
  }, [childrenContainerRef]);

  return (
    <div
      style={{ width }}
      className={clsx('horizontal-loop-container', className)}
    >
      <div className={'horizontal-loop-inner'}>
        {[...new Array(copies)].map((_, i) => (
          <React.Fragment key={i}>{children}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default HorizontalAutoScrollView;
