import { useEffect, useState } from 'react';

/**
 * @param {{maxWidth:number|undefined; minWidth:number|undefined}} options Options
 * @returns
 */
const useMediaQuery = ({
  maxWidth = Number.MAX_SAFE_INTEGER,
  minWidth = 0,
}) => {
  const [value, setValue] = useState(
    () => typeof window !== 'undefined' ? window.innerWidth >= minWidth && window.innerWidth <= maxWidth : false
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const onWindowResize = () => {
        const check =
          window.innerWidth >= minWidth && window.innerWidth <= maxWidth;
        if (check !== value)
          setValue(
            () => window.innerWidth >= minWidth && window.innerWidth <= maxWidth
          );
      };
      window.addEventListener('resize', onWindowResize);

      return () => window.removeEventListener('resize', onWindowResize);
    }
  }, [maxWidth, minWidth, value]);

  return value;
};

export const useDesktopMediaQuery = () => useMediaQuery({ minWidth: 1280 });

export const useTabletAndBelowMediaQuery = () =>
  useMediaQuery({ maxWidth: 1279 });

export const useMobileMediaQuery = () => useMediaQuery({ maxWidth: 767 });

export const useTableAndAboveMediaQuery = () =>
  useMediaQuery({ minWidth: 768 });

export const Desktop = ({ children }) =>
  useDesktopMediaQuery() ? children : null;

export const TabletAndBelow = ({ children }) =>
  useTabletAndBelowMediaQuery() ? children : null;

export const TabletAndAbove = ({ children }) =>
  useTableAndAboveMediaQuery() ? children : null;

export const Mobile = ({ children }) =>
  useMobileMediaQuery() ? children : null;
