import React from 'react';
// import SocialNetworkBtn from './SocialNetworkBtn';
import logo from '../../logo.svg';
import Btn from '../Btn/Btn';
import PropTypes from 'prop-types';
import LangSwitch from '../LangSwitch/LangSwitch';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = ({ pinnedToBottom = true }) => {
  const { t } = useTranslation();

  return (
    <>
      {pinnedToBottom && <div className="mt-auto" />}
      <footer className="w-full text-xs mt-12 border-t border-white border-opacity-30">
        <div className="box-border w-full max-w-7xl mx-auto px-4 text-xs flex flex-col lg:flex-row justify-strech items-center py-16">
          <div className="flex-none mt-6 md:mb-6 mb-12 lg:mt-0 mr-0 lg:mr-12">
            <Link to="/">
              <img src={logo} alt="PXL" />
            </Link>
          </div>
          <div className="flex-1 flex flex-col items-stretch">
            <div className="w-full flex flex-col lg:flex-row lg:items-center items-start">
              <div className="mr-4 mb-4 lg:mb-0">
                <Btn to="/tos" variant="discrete" size="xs">
                  {t('tos')}
                </Btn>
              </div>
              <div className="mr-4 mb-4 lg:mb-0">
                <Btn to="/privacy" variant="discrete" size="xs">
                  {t('privacy')}
                </Btn>
              </div>
              <div className="lg:px-0 px-4">
                <LangSwitch />
              </div>
            </div>
            <hr className="w-full opacity-30 my-6" />
            <div className="w-full flex flex-col lg:flex-row justify-between items-center px-3">
              <div className="flex items-center pb-6 lg:pb-0">
                {/* <p className="mr-6">{t('follow_us')}</p>
                <SocialNetworkBtn
                  link={'https://twitter.com'}
                  name={'twitter'}
                />
                <SocialNetworkBtn
                  link={'https://facebook.com'}
                  name={'facebook'}
                />
                <SocialNetworkBtn
                  link={'https://instagram.com'}
                  name={'instagram'}
                /> */}
              </div>
              <div>
                <p>Copyright PXL 2021. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  pinnedToBottom: PropTypes.bool,
};

export default Footer;
