import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './Btn.css';
import clsx from 'clsx';

const Btn = ({
  activeVariant = null,
  children = null,
  className = '',
  disabled = false,
  hoverVariant = null,
  href = null,
  onClick = () => {},
  size = 'normal',
  to = null,
  type = 'button',
  variant = '',
  ...otherProps
}) => {
  // must do it this way for Tailwind
  let variantClasses = variant;
  if (hoverVariant) variantClasses += ` hover-${hoverVariant}`;
  if (activeVariant) variantClasses += ` active-${activeVariant}`;

  const classes = clsx(
    'pxl-btn pxl-display-font',
    className,
    variantClasses,
    size
  );

  if (to && !disabled) {
    return (
      <Link onClick={onClick} className={classes} to={to} {...otherProps}>
        <div>{children}</div>
      </Link>
    );
  } else if (href && !disabled) {
    return (
      <a
        className={classes}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
        {...otherProps}
      >
        <div>{children}</div>
      </a>
    );
  } else {
    return (
      <button
        type={type}
        disabled={disabled}
        className={classes}
        onClick={onClick}
        {...otherProps}
      >
        <div>{children}</div>
      </button>
    );
  }
};

Btn.propTypes = {
  activeVariant: PropTypes.oneOf(['accent', 'primary']),
  className: PropTypes.string,
  children: PropTypes.node,
  hoverVariant: PropTypes.oneOf(['accent', 'danger', 'primary']),
  href: PropTypes.string,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'small', 'normal', 'large']),
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf([
    'danger',
    'discrete',
    'inverted',
    'primary',
    'with-border',
  ]),
};

export default Btn;
