import clsx from 'clsx';
import PropTypes from 'prop-types';

const InputField = ({
  label,
  fullWidth,
  multiline,
  inputStyle,
  labelStyle,
  labelClassName,
  inputClassName,
  wrapperClassName,
  required,
  ...inputProps
}) => {
  return (
    <div
      className={clsx(`mb-10 flex flex-col items-start`, {
        'w-full': fullWidth,
      }, wrapperClassName)}
    >
      {label && <label
        className={clsx('mb-7', labelClassName)}
        htmlFor={inputProps.id}
        style={labelStyle}
      >
        {label}
        {require && (
          <>
            {' '}
            <span className='text-pxl-redColor'>＊</span>
          </>
        )}
      </label>}
      {!multiline ? (
        <input
          required={required}
          class={clsx(
            'border-none bg-pxl-inputBackground py-6 px-7',
            inputClassName,
            {
              'w-full': fullWidth,
            }
          )}
          style={inputStyle}
          {...inputProps}
        />
      ) : (
        <textarea
          required={required}
          class={clsx(
            'border-none bg-pxl-inputBackground py-6 px-7',
            inputClassName,
            {
              'w-full': fullWidth,
            }
          )}
          style={inputStyle}
          {...inputProps}
        />
      )}
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  labelClassName: PropTypes.string,
  inputClassname: PropTypes.string,
  wrapperClassName: PropTypes.string,
  required: PropTypes.bool,
};

export default InputField;
