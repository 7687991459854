import clsx from 'clsx';
import wave from '../assets/img/wave.svg';
import PropTypes from 'prop-types';

const WaveDivider = ({ className }) => (
  <img
    className={clsx('w-[1920px] max-w-none my-16', className)}
    src={wave}
    alt="wave"
  />
);

WaveDivider.propTypes = {
  className: PropTypes.string,
};

export default WaveDivider;
