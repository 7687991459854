import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { rest } from '@karpeleslab/klbfw';
import { run } from '@karpeleslab/react-klbfw-hooks';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Backend } from '@karpeleslab/i18next-klb-backend';
import { getLocale } from '@karpeleslab/klbfw';

let i18nOpt = {
  lng: getLocale(),
  initImmediate: false,
  load: 'currentOnly',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
    transKeepBasicHtmlNodesFor: ['br', 'span', 'strong', 'i', 'p'],
  },
};

global.rest = rest;

run(<App />, [i18n.use(Backend).use(initReactI18next).init(i18nOpt)]);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
