import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import ContactPage from './pages/Contact';
import FAQPage from './pages/FAQ';
import FeaturesPage from './pages/Features';
import ForgotPasswordPage from './pages/ForgotPassword';
import HomePage from './pages/Home';
import LegalPage from './pages/Legal';
import NewsPage from './pages/News';
import NewsDetailPage from './pages/NewsDetail';
import NotFoundPage from './pages/NotFound';
import PrivacyPage from './pages/Privacy';
import RecoverPasswordPage from './pages/RecoverPassword';
import TermsOfServicePage from './pages/TermsOfService';

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/features" component={FeaturesPage} />
        <Route exact path="/news" component={NewsPage} />
        <Route
          exact
          path="/news/:slug"
          render={({ match: { params } }) => (
            <NewsDetailPage slug={params.slug} />
          )}
        />
        <Route exact path="/faq" component={FAQPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/tos" component={TermsOfServicePage} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/legal" component={LegalPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route
          exact
          path="/recover-password/:userId/:key*"
          component={RecoverPasswordPage}
        />
        <Route
          path="**"
          render={({ location: { pathname } }) => (
            <NotFoundPage key={pathname} />
          )}
        />
      </Switch>
    </>
  );
}

export default App;
