import { rest } from '@karpeleslab/klbfw';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Alert from '../components/Alert';
import Btn from '../components/Btn/Btn';
import Container from '../components/Container';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import InputField from '../components/InputField';
import Loader from '../components/Loader';
import PageBanner from '../components/PageBanner/PageBanner';
import LayoutHelmet from '../components/LayoutHelmet';

const RecoverPasswordPage = () => {
  const { t } = useTranslation();
  const { userId, key } = useParams();
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pwd, setPwd] = useState('');
  const [pwdConfirm, setPwdConfirm] = useState('');
  const [error, setError] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError();
    rest('User:recover_password', 'POST', {
      login: userId,
      key,
      p1: pwd,
      p2: pwdConfirm,
    })
      .then(() => setSent(true))
      .catch((err) => setError(`${err.error}`))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <LayoutHelmet title={t('reset_password')} />
      <Header noPlaceholder />
      <PageBanner title={t('reset_password')} />
      <Container component="main">
        {sent ? (
          <Alert>
            <p>{t('confirm_pwd_reset')}</p>
          </Alert>
        ) : (
          <form
            key="reset-pwd-form"
            action=""
            className="w-full flex flex-col items-center"
            onSubmit={onSubmit}
          >
            <InputField
              fullWidth
              label={t('new_password')}
              type="password"
              value={pwd}
              required={true}
              onChange={(e) => setPwd(e.target.value)}
            />
            <InputField
              fullWidth
              label={t('confirm_new_password')}
              type="password"
              value={pwdConfirm}
              required={true}
              onChange={(e) => setPwdConfirm(e.target.value)}
            />
            {error && (
              <Alert className="mb-8" type="error">
                {error}
              </Alert>
            )}
            {!loading ? (
              <Btn
                size="large"
                variant="primary"
                type="submit"
                className="mt-4"
              >
                {t('send')}
              </Btn>
            ) : (
              <div key="request-loading" className="w-full">
                <Loader />
              </div>
            )}
          </form>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default RecoverPasswordPage;
