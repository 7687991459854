import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';

const useAnchorListener = () => {
  const { hash } = useLocation();

  useLayoutEffect(() => {
    if (hash.length) {
      document.querySelector(hash).scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    } else {
      document.querySelector('#root').scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    }
  }, [hash]);
};

export default useAnchorListener;
