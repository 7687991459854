import { useEffect, useState } from 'react';

const useScrollTrigger = ({ threshold = 10 }) => {
  const [state, setState] = useState(() => typeof window !== 'undefined' ? window.scrollY > threshold : false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const onScroll = () => {
        const check = window.scrollY > threshold;
        if (check !== state) setState(check);
      };

      window.addEventListener('scroll', onScroll);

      return () => window.removeEventListener('scroll', onScroll);
    }
  });

  return state;
};

export default useScrollTrigger;
