import clsx from 'clsx';
import React from 'react';
import { Mobile, TabletAndAbove } from '../../hooks/useMediaQueries';
import './FeatureSection.css';

const FeatureSection = ({
  variant = 'overlap',
  reverse = false,
  imgSrc,
  imgAlt,
  title,
  sub,
  cardContent,
  subtitle,
  description,
  classes = {},
  style = {},
}) => {
  const contentClassName = {
    'max-w-[400px]': variant === 'normal' && !reverse,
  };

  return (
    <>
      <TabletAndAbove>
        <div
          id={title}
          className={clsx('feature-section', {
            side: variant === 'side',
            normal: variant === 'normal',
            overlap: variant === 'overlap',
            reverse,
          })}
          style={{
            backgroundImage: `url(${imgSrc})`,
            ...style,
          }}
        >
          <div className={'inline-flex flex-col items-start feature-section-text'}>
            <h6
              className={clsx('feature-title', classes.title)}
            >
              {title}
            </h6>
            <p
              className={clsx(
                'feature-subtitle',
                contentClassName,
                classes.subtitle
              )}
            >
              {sub}
            </p>
            <div
              className={clsx(
                'feature-category pxl-gradient-card',
                contentClassName
              )}
            >
              {cardContent}
            </div>
            <div
              className={clsx('feature-text1', contentClassName, classes.text1)}
            >
              {subtitle}
            </div>
            <div
              className={clsx('feature-text2', contentClassName, classes.text2)}
            >
              {description}
            </div>
          </div>

        </div>
      </TabletAndAbove>
      <Mobile>
        <div id={title} className={clsx('flex flex-col')}>
          <img
            className={clsx('object-cover mb-[46px]', classes.img)}
            src={imgSrc}
            alt={imgAlt}
          />
          <div>
            <h6 className="text-[50px]" style={{ color: '#73737C' }}>
              {title}
            </h6>
            <p className="text-[16px] mb-[43px]" style={{ color: '#73737C' }}>
              {sub}
            </p>
            <div className="pxl-gradient-card inline-block mb-[18px]">
              {cardContent}
            </div>
            <div className="text-white text-[24px] mb-[28px]">{subtitle}</div>
            <div className="text-white text-[14px]">{description}</div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default FeatureSection;
