import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Moment from 'react-moment';

const Card = ({
  children,
  classes = {},
  component: Component,
  imgSrc,
  imgAlt,
  ...otherProps
}) => {
  const content = (
    <>
      <img
        src={imgSrc}
        alt={imgAlt}
        className={clsx('max-h-[320px] object-cover mb-8', classes.img)}
      />
      {children}
    </>
  );

  return Component ? (
    <Component
      className={clsx('flex flex-col', classes.container)}
      {...otherProps}
    >
      {content}
    </Component>
  ) : (
    <div className={clsx('flex flex-col', classes.container)}>{content}</div>
  );
};

Card.propTypes = {
  classes: PropTypes.any,
  component: PropTypes.elementType,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
};

export const FeatureCard = ({ imgSrc, imgAlt, sub, subtitle, to }) => (
  <Card
    classes={{ img: 'h-full' }}
    component={Link}
    to={to}
    imgSrc={imgSrc}
    imgAlt={imgAlt}
  >
    <p className={'text-center'}>{sub}</p>
    <p className={'text-center'}>{subtitle}</p>
  </Card>
);

export const NewsCard = ({
  className,
  imgSrc,
  imgAlt,
  date,
  title,
  to,
  tags = []
}) => (
  <Card
    classes={{ container: className, img: 'h-full mb-4' }}
    component={Link}
    to={to}
    imgSrc={imgSrc}
    imgAlt={imgAlt}
  >
    <div className={'flex align-center mb-4'}>
      {tags.map(t => <div className={'pxl-gradient-card mr-2'}>{t.Name}</div>)}
      <Moment className={'flex items-center'} format={'YYYY.MM.DD'}>
        {date}
      </Moment>
    </div>
    <p>{title}</p>
  </Card>
);

export default Card;
