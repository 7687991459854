import React, { createRef, useLayoutEffect } from 'react';
import clsx from 'clsx';
import './Collapsible.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const Collapsible = ({
  children,
  className,
  open,
  startIcon,
  expandIcon,
  title,
  content,
  onClick,
}) => {
  const contentRef = createRef();

  useLayoutEffect(() => {
    /**
     * @type {HTMLDivElement|undefined}
     */
    const container = contentRef && contentRef.current;
    if (container) {
      if (open) {
        container.style.maxHeight = `${container.scrollHeight}px`;
      } else {
        container.style.maxHeight = null;
      }
    }
  }, [contentRef, open, children, content]);

  return (
    <div className={clsx('collapsible', className)}>
      <button
        className={clsx('collapsible-button', { active: open })}
        onClick={onClick}
      >
        {startIcon && <div className="mr-6">{startIcon}</div>}
        <span className="flex-1 text-left">{title}</span>
        <div className="ml-6">
          {expandIcon || <FontAwesomeIcon icon={open ? faMinus : faPlus} />}
        </div>
      </button>
      <div
        ref={contentRef}
        className={clsx('collapsible-content-wrapper', { open })}
      >
        <div className="collapsible-content">{content || children}</div>
      </div>
    </div>
  );
};

export default Collapsible;
