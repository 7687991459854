import React from 'react';
import './SectionTitle.css';
import wave from '../../assets/img/wave.svg';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const SectionTitle = ({ className, children, title, reverse = false }) => {
  return (
    <div className={clsx('section-title-wrapper', { reverse }, className)}>
      <span className={clsx('title', { reverse })}>{children || title}</span>
      <img className={clsx('wave', { reverse })} src={wave} alt={'wave'} />
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string,
  reverse: PropTypes.bool,
};

export default SectionTitle;
